import React, { useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import axios from "axios";
import { toast } from "react-toastify";
import imgBack from "../../../src/images/mailz.jpeg";
import load1 from "../../../src/images/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../../PortfolioContainer/footer/Footer";
import emailjs from "@emailjs/browser";
import "./ContactMe.css";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  console.log(name);
  // const submitForm = async (e) => {
  //   e.preventDefault();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending email...");
    const serviceId = "service_my46tho";
    const templateId = "template_pjqxgg3";
    const publicKey = "vb_eHv6f8y_XD3SSF";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Ticha Nji",
      message: message,
    };
    setBool(true);
    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setEmail("");
        setMessage("");
        setBool(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
    //  };

    //   try {
    //     let data = {
    //       name,
    //       email,
    //       message,
    //     };
    //     setBool(true);
    //     const res = await axios.post(`/contact`, data);
    //     if (name.length === 0 || email.length === 0 || message.length === 0) {
    //       setBanner(res.data.msg);
    //       toast.error(res.data.msg);
    //       setBool(false);
    //     } else if (res.status === 200) {
    //       setBanner(res.data.msg);
    //       toast.success(res.data.msg);
    //       setBool(false);

    //       setName("");
    //       setEmail("");
    //       setMessage("");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="col">
          <h2 className="title">
            <Typewriter
              options={{
                strings: ["Get In Touch 📧"],
                autoStart: true,
                loop: true,
              }}
            />
          </h2>{" "}
          <a href="#">
            <i className="fa fa-facebook-square" />
          </a>
          <a href="#">
            <i className="fa fa-google-plus-square" />
          </a>
          <a href="#">
            <i className="fa fa-instagram" />
          </a>
          <a href="#">
            <i className="fa fa-youtube-square" />
          </a>
          <a href="#">
            <i className="fa fa-twitter" />
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>Send Your Email Here!</h4>
            <img src={imgBack} alt="image not found" />
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input
            required
              type="text"
              name="user_name"
              onChange={handleName}
              value={name}
            />

            <label htmlFor="email">Email</label>
            <input
            required
                type="email"
              name="user_email"
              value={email}
              onChange={handleEmail}
              
            />

            <label htmlFor="message">Message</label>
            <textarea
            required
              type="text"
              name="message"
              value={message}
              onChange={handleMessage}
             
            />

            <div className="send-btn">
              <button type="submit">
                send
                <i className="fa fa-paper-plane" />
                {bool ? (
                  <b className="load">
                    <img src={load1} alt="image not responding" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
